<template>
    <div class="ps-goals">
        <div class="one-title-drop">
            <div>
                <van-dropdown-menu active-color="#3F7C53" class="width-130">
                    <van-dropdown-item
                            v-model="targetDate"
                            :options="dateList"
                            @change="dateChange"
                    />
                </van-dropdown-menu>
            </div>
            <div>
                <van-dropdown-menu active-color="#3F7C53" class="width-130">
                    <van-dropdown-item v-model="deptNo" :options="dropList" @change="changeDept"/>
                </van-dropdown-menu>
            </div>
        </div>

        <van-tabs v-model="active" color="#3f7c53" @change="tabChange">
            <van-tab v-for="item in tabList"
                     :title="item.title" :key="item.value">

                <notTitleGoals :cityData="cityData" :tabType="stationType" v-if="!loadingCityData"> </notTitleGoals>

                <van-pull-refresh v-model="isLoading"
                                  @refresh="onRefresh">
                    <van-list v-model="loading"
                              :finished="finished"
                              finished-text="没有更多了"
                              @load="onLoad">
                        <div class="sub-goals">
                            <hasTitleGoals :dataList="dataList" :tabType="stationType"> </hasTitleGoals>
                        </div>
                    </van-list>
                </van-pull-refresh>

            </van-tab>
        </van-tabs>

        <div class="botton-box">
            <van-button class="publicBtnActive" @click="jumpTo">运营部及成员排名</van-button>
        </div>

    </div>
</template>

<script>
    import hasTitleGoals from "@/components/hasTitleGoals";
    import notTitleGoals from "@/components/notTitleGoals";
    import {getPerformanceRanking} from '@/utils/goals';
    import common from '@/utils/common';
    import moment from "moment";
    export default {
        name: "cityLevelGoals",
        components:{hasTitleGoals,notTitleGoals},
        data(){
            return{
                dropList:common.getItem('cityList'),
                deptNo:common.getItem('cityList')[0].value,//运营部的code
                year: new Date().getFullYear(), //获取当前年
                month: new Date().getMonth() + 1, //获取当前月
                tabList:[
                    {title:'新建站点',value:'NewlyStation'},
                    {title:'有效站点',value:'ActiveStation'},
                    {title:'双百站点',value:'DoubleHundred'},
                    {title:'时点余额',value:'PointBalance'},
                   // {title:'达标站点',value:'ReachToQs'},
                    {title:'上线站点',value:'onlineStation'}
                ],
                tabEnume: { //tab枚举
                    0: 'NewlyStation',
                    1: 'ActiveStation',
                    2: 'DoubleHundred',
                    3: 'PointBalance',
                    4: 'onlineStation'
                },
                cityData:{},
                dataList:[],
                stationType:'NewlyStation',
                isLoading:false,
                loading: false,
                finished: false,
                active: 0, //默认新建站点
                page:1,
                size:10,
                loadingCityData: false,
                dateList:[
                    {text:moment().subtract(3,'months').format('YYYY/MM'),
                        value:moment().subtract(3,'months').format('YYYY/MM')},
                    {text:moment().subtract(2,'months').format('YYYY/MM'),
                        value:moment().subtract(2,'months').format('YYYY/MM')},
                    {text:moment().subtract(1,'months').format('YYYY/MM'),
                        value:moment().subtract(1,'months').format('YYYY/MM')},
                    {text:moment().format('YYYY/MM'),value: moment().format('YYYY/MM')},
                ],
                //每月10号前显示上月,否则显示本月
                targetDate:moment().format("YYYY-MM-DD")>moment().date(10).format('YYYY-MM-DD') ?
                    moment().format('YYYY/MM'):
                    moment().subtract(1,'months').format('YYYY/MM'),
            }
        },
        methods:{
            dateChange(){
                this.page = 1;
                this.dataList = [];
                this.cityData = {};
                this.seachData();
            },
            //下拉框
            changeDept(){
                this.page = 1;
                this.dataList = [];
                this.cityData = {};
                this.seachData();
            },

            //切换tab类型
            tabChange(value){
                this.stationType = this.tabEnume[value];
                this.page = 1;
                this.dataList = [];
                this.cityData = {};
                this.seachData();
            },
            //查询业绩目标与达成
            seachData(){
                let data = {
                    page:this.page,
                    size:this.size,
                    targetDate: this.targetDate,//this.year+'/'+this.month,
                    rankingLevel: 'PROVINCE',
                    rankingType:'PERSON',//地区排名：AREA；人员排名：PERSON
                    objNo:this.deptNo,
                    stationType:this.stationType,
                }
                this.loadingCityData = true;
                getPerformanceRanking(data).then(res=>{
                    this.loading = false;
                    this.loadingCityData = false;
                    if(res.code===200 && res.data.businessCode ===200){
                        this.finished = !res.data.rankList.hasNextPage;
                        this.cityData = res.data.rankList.current;

                        if (this.page === 1) {
                            this.dataList = res.data.rankList.rankingList;
                        } else {
                            this.dataList = this.dataList.concat(res.data.rankList.rankingList);
                        }
                        if (!this.finished) {
                            this.page++;
                        }
                    }
                    if(!res.data.rankList){//如果没有数据
                        this.finished = true;
                    }
                })
            },
            onLoad(){
                this.seachData();
            },
            //下拉刷新
            onRefresh() {
                this.isLoading = false;
                this.dataList = [];
                this.page = 1;
                this.seachData();
            },
            //跳转到运营部及人员排名
            jumpTo(){
                this.$router.push({
                    path:'/ps/ranking',
                    query:{
                        deptNo: this.deptNo,
                        targetDate: this.targetDate
                    }
                })
            }
        }
    }
</script>

<style lang="less">
    @import "../../style/goals";

</style>
